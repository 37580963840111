import { API_URL, LOCAL_ENV } from '@lib/constants/environment';

export async function postErrorMessage(errorMessage: unknown): Promise<unknown> {
  if (!LOCAL_ENV) {
    return await fetch(`${API_URL}/error-log`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(errorMessage),
      cache: 'no-cache'
    });
  }
  return Promise.resolve();
}
